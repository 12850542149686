import { IS_DEV } from "@/__main__/constants.mjs";
import { getSeasons } from "@/game-apex/apex-fetch-static.mjs";
import {
  GAME_MODE_STATS_KEYS,
  GAME_MODES,
  LIFETIME_SEASON,
} from "@/game-apex/constants.mjs";
import createOrUpdatePlayerLegendStat from "@/game-apex/fetches/create-or-update-player-legend-stat.mjs";
import type {
  AllModeAllSeasonData,
  AllModeSeasonData,
  MiscData,
  ModeAllSeasonData,
  ModeAndSeasonData,
  PlayerStats,
  RankedSeasonData,
} from "@/game-apex/fetches/create-or-update-player-season-stat.mjs";
import createOrUpdatePlayerSeasonStat, {
  getStatsFromData,
  mergeStats,
} from "@/game-apex/fetches/create-or-update-player-season-stat.mjs";
import createOrUpdatePlayerWeaponStat from "@/game-apex/fetches/create-or-update-player-weapon-stat.mjs";
import getProfile from "@/game-apex/fetches/get-profile.mjs";
import type { EventApexData } from "@/game-apex/models/event-apex-data.mjs";
import {
  EventApexDataValidate,
  EventCharactersDataValidate,
  EventRankedPeriod2DataValidate,
  EventRankedPeriodDataValidate,
  EventRankedStatPeriodDataValidate,
  EventSeasonsDataValidate,
  EventWeaponsDataValidate,
} from "@/game-apex/models/event-apex-data.mjs";
import type { ApexSeason, Seasons } from "@/game-apex/models/seasons.mjs";
import { getCurrentSeason } from "@/game-apex/utils.mjs";
import { devError, devLog } from "@/util/dev.mjs";

async function handlePlayerStats(
  { profileId, blitzId }: { profileId: string; blitzId: string },
  parsed: EventApexData,
  {
    seasons,
    latestSeason,
    rankedId,
  }: { seasons: Seasons; latestSeason: ApexSeason; rankedId?: string },
) {
  // The BE API requires game mode and season to create or update the player season stats
  for (const mode of GAME_MODE_STATS_KEYS) {
    // Looping over all supported game modes
    const gameMode = GAME_MODES[mode];
    for (const seasonApexId in seasons) {
      // Looping over all seasons
      const season: ApexSeason = seasons[seasonApexId];
      const seasonId = season.id;
      // Guards
      if (latestSeason.id !== seasonId && seasonId !== LIFETIME_SEASON.id) {
        // Prevent updating the BE with old historical stats from previous seasons
        // Only update lifetime and current season stats
        // The rest of the logic is written as if this check is not there. So it supports all the seasons.
        devLog(
          `Apex Legends: Skipping player season stat update for game mode(${mode}) and season apex id(${seasonApexId}) because it is an old season`,
        );
        continue;
      }
      // Constant misc.
      const misc: MiscData = { blitzId };
      let statsBySeason: PlayerStats | undefined;
      // Ranked special cases
      switch (gameMode.statsMode) {
        case GAME_MODES.ALL.statsMode: {
          let data: AllModeAllSeasonData | AllModeSeasonData;
          if (season.id === LIFETIME_SEASON.id) {
            data = parsed.stats;
          } else {
            data = parsed.stats.seasons[seasonApexId];
          }
          if (data) statsBySeason = getStatsFromData(data);
          break;
        }
        // TODO: Add Ranked Arenas mode here
        // Add ranked flavors here if they exist
        case GAME_MODES.RANKED.statsMode: {
          // iterate over all ranked period ids
          const rankedPeriodIds =
            season.id === LIFETIME_SEASON.id
              ? Object.keys(parsed.stats.rankedperiods)
              : season.apexRankedPeriodIds;
          for (const rankedPeriodId of rankedPeriodIds) {
            const rankedPeriodStats: RankedSeasonData =
              parsed.stats.rankedperiods[rankedPeriodId];
            if (!rankedPeriodStats || rankedPeriodStats.games_played === 0)
              continue;
            // Note: Assuming that there are multiple ranked stat periods in the same season, combine them all
            const requiredStats = getStatsFromData(rankedPeriodStats);
            statsBySeason = statsBySeason
              ? mergeStats(requiredStats, statsBySeason)
              : requiredStats;
          }
          if (
            rankedId &&
            (season.apexRankedPeriodIds.includes(rankedId) ||
              season.id === LIFETIME_SEASON.id)
          ) {
            misc.ranked = getRankedData(rankedId, parsed);
          }
          break;
        }
        default: {
          // Sending game mode + season data to BE
          let data: ModeAllSeasonData | ModeAndSeasonData;
          if (season.id === LIFETIME_SEASON.id) {
            // Lifetime stats for the mode
            data = parsed.stats.modes[gameMode.EModeFlavour];
          } else {
            // season specific stats for the mode
            data =
              parsed.stats.modes[gameMode.EModeFlavour]?.seasons[seasonApexId];
          }
          if (data) statsBySeason = getStatsFromData(data);
        }
      }
      if (!statsBySeason) continue;
      await createOrUpdatePlayerSeasonStat(
        // Identifying information
        {
          profileId,
          gameMode: gameMode.statsMode,
          seasonId,
          seasonNumber: seasons[seasonApexId].apexSeasonNumber,
        },
        // Payload stats
        statsBySeason,
        // Payload stats misc.
        misc,
      );
    }
  }
}

async function handleLegendsStats(
  { profileId, blitzId }: { profileId: string; blitzId: string },
  data: EventApexData,
  latestSeason: ApexSeason,
) {
  for (const mode in data.stats.modes) {
    // We don't have per season stats for legends and weapons. So updating only lifetime stats
    const gameMode = Object.values(GAME_MODES).find(
      (g) => g.EModeFlavour === mode,
    );
    if (!gameMode) continue;
    // update legends stats
    await createOrUpdatePlayerLegendStat(
      {
        profileId,
        gameMode: gameMode.statsMode,
        seasonId: LIFETIME_SEASON.id,
        seasonNumber: LIFETIME_SEASON.apexSeasonNumber,
      },
      data.stats.modes[gameMode.EModeFlavour].characters,
      { blitzId },
    );
  }

  await createOrUpdatePlayerLegendStat(
    {
      profileId,
      gameMode: GAME_MODES.ALL.statsMode,
      seasonId: latestSeason.id,
      seasonNumber: latestSeason.apexSeasonNumber,
    },
    data.stats.seasons[latestSeason.apexId].characters,
    { blitzId },
  );

  // we don't have per season stats for legends. So updating only lifetime stats
  // update legends stats
  await createOrUpdatePlayerLegendStat(
    {
      profileId,
      gameMode: GAME_MODES.ALL.statsMode,
      seasonId: LIFETIME_SEASON.id,
      seasonNumber: LIFETIME_SEASON.apexSeasonNumber,
    },
    data.stats.characters,
    { blitzId },
  );
}

async function handleWeaponsStats(
  { profileId, blitzId }: { profileId: string; blitzId: string },
  data: EventApexData,
) {
  // we don't have per season stats for weapons. So updating only lifetime stats
  for (const mode in data.stats.modes) {
    const gameMode = Object.values(GAME_MODES).find(
      (g) => g.EModeFlavour === mode,
    );
    if (!gameMode) continue;
    // update weapon stats
    await createOrUpdatePlayerWeaponStat(
      {
        profileId,
        gameMode: gameMode.statsMode,
        seasonId: LIFETIME_SEASON.id,
        seasonNumber: LIFETIME_SEASON.apexSeasonNumber,
      },
      data.stats.modes[gameMode.EModeFlavour].weapons,
      { blitzId },
    );
  }

  // update weapon stats
  await createOrUpdatePlayerWeaponStat(
    {
      profileId,
      gameMode: GAME_MODES.ALL.statsMode,
      seasonId: LIFETIME_SEASON.id,
      seasonNumber: LIFETIME_SEASON.apexSeasonNumber,
    },
    data.stats.weapons,
    { blitzId },
  );
}

function getRankedData(
  rankedPeriodId: string,
  parsed: EventApexData,
): MiscData["ranked"] {
  const rankedPeriodStats: RankedSeasonData =
    parsed.stats.rankedperiods[rankedPeriodId];
  return {
    rankedPoints: rankedPeriodStats.current_rank_score,
  };
}

function lookForNewIds(data: EventApexData) {
  if (!IS_DEV) return;
  const config = { failOnError: IS_DEV };
  EventSeasonsDataValidate(data.Enums.eseasonflavor, config);
  EventRankedPeriodDataValidate(data.Enums.erankedperiodflavor, config);
  EventRankedStatPeriodDataValidate(data.Enums.erankedstatperiod, config);
  EventRankedPeriod2DataValidate(data.Enums.erankedperiod2pt0flavor, config);
  EventCharactersDataValidate(data.Enums.echaracterflavor, config);
  EventWeaponsDataValidate(data.Enums.eweaponflavor, config);
}

export default async function handleApexData(
  profileId: string,
  data: unknown,
  rankedSeasonId?: string,
) {
  try {
    // FIXME (@vivek): This validator is bugged. If any extra data is passed to the validator, its replacing it with a value from the predefined Union list value.
    const parsed = EventApexDataValidate(data);
    lookForNewIds(parsed);
    // Fetch or access state for seasons and profile
    const [seasons, profile] = await Promise.all([
      getSeasons({
        shouldFetchIfPathExists: false,
        skipSafetyCheck: true,
      }),
      getProfile(profileId).catch((): undefined => {}),
    ]);
    // Constants
    const blitzId = profile?.id;
    const latestSeason = getCurrentSeason(seasons);
    // Guards
    if (!blitzId) throw new Error("Blitz id required");
    if (!latestSeason?.id) throw new Error("Latest season id required");

    const playerIdentity = { profileId, blitzId };
    const seasonsInfo = {
      seasons,
      latestSeason: latestSeason as ApexSeason,
      rankedId: rankedSeasonId,
    };
    await handlePlayerStats(playerIdentity, parsed, seasonsInfo);
    await handleLegendsStats(
      playerIdentity,
      parsed,
      latestSeason as ApexSeason,
    );
    await handleWeaponsStats(playerIdentity, parsed);
  } catch (e) {
    devError("Apex Legends: Failed to handle player data", e);
  }
}

export function parseRawData(data: ApexDataRaw): ApexData {
  const payload: ApexData = {};
  parseAndAssign(payload, "data", data.dataStr);
  parseAndAssign(payload, "diffs", data.diffsStr);
  parseAndAssign(payload, "lastMatch", data.lastMatchStr);
  if (data.diffRankSeasonId) payload.diffRankSeasonId = data.diffRankSeasonId;
  return payload;
}

function parseAndAssign(ref: object, key: keyof ApexData, str: string): void {
  try {
    ref[key] = JSON.parse(str);
  } catch {
    devError(
      `Apex Legends (Critical Error): Failed to parse JSON string for key "${key}"`,
    );
  }
}

export type ApexDataRaw = {
  dataStr: string;
  diffsStr: string;
  lastMatchStr: string;
  diffRankSeasonId?: string;
};

export type WeaponsBattleRoyale = {
  damage_done: number;
  dooms: number;
  headshots: number;
  hits: number;
  kills: number;
  shots: number;
};

type WeaponsMixTape = {
  damage_done: number;
  dooms: number;
  headshots: number;
  kills: number;
};

type Diff = {
  weapons: {
    [weaponApexId: string]: WeaponsMixTape | WeaponsBattleRoyale;
  };
  rank?: {
    games_played: number;
    current_rank_score: number;
    total_ranked_points: number;
  };
  games_played_any_mode: number;
};

export type ApexData = {
  data?: {
    Enums: { eseasonflavor: Array<string> };
    lastgamemode: unknown;
    lastgametime: unknown;
    lastgamesquadstats: Array<{
      assists: number;
      character: number;
      damagedealt: number;
      displaydata2: number;
      displaydata3: number;
      displaydata3istime: boolean;
      displaydata4: number;
      displaydata5: number;
      ehandle: number;
      hardwareid: number;
      kills: number;
      knockdowns: number;
      nucleusid: string;
      platformuid: string;
      playername: string;
      respawnsgiven: number;
      revivesgiven: number;
      survivaltime: number;
      unused: number;
    }>;
    lastgamerank: number;
    lastgamecharacter: unknown;
    xp: unknown;
  };
  diffs?: Diff;
  lastMatch?: {
    apexId: string; // Match ID
    map: string;
    gameMode: string;
    gameStartedAt: number;
    teamId: string;
  };
  diffRankSeasonId?: string;
};

export type Stats = {
  [_: string]: unknown;
  platformId: string;
  dooms: number;
  headshots: number;
  hits: number;
  shots: number;
  userAccountId?: number;
  rankedPoints?: number;
  total_ranked_points?: number;
  team: {
    apexId: string;
    placement: number;
  };
  playerMatchWeaponStats: Array<
    {
      weapon: { apexId: string };
      weaponId: string;
      damageDone: number;
    } & Diff["weapons"][string]
  >;
};
